import { onCLS, onFID, onLCP } from "web-vitals/attribution";

function init() {
	// console.log("Web vitals tests");

	// CLS
	// Log CLS as the value changes
	onCLS(console.log, { reportAllChanges: true });

	// LCP
	onLCP(console.log);

	// FID
	onFID(console.log);
}

export default { init };
