import ada from "./modules/ada";
import astra from "./modules/astra";
import dialogContact from "./modules/dialogs/contact";
import dialogFreeEstimate from "./modules/dialogs/free-estimate";
import dialogGlobal from "./modules/dialogs/global";
import dialogVideo from "./modules/dialogs/video";
import eligibility from "./modules/eligibility";
import embedSocial from "./modules/embedsocial";
import faq from "./modules/faq";
import pagination from "./modules/pagination";
import sliderBanner from "./modules/sliders/banner";
import sliderDownload from "./modules/sliders/download";
import sliderGrid from "./modules/sliders/grid";
import sliderLogo from "./modules/sliders/logo";
import sliderTestimonial from "./modules/sliders/testimonial";
import test from "./modules/test";
import coreWebVitals from "./modules/tests/core-web-vitals";
import utils from "./modules/utils";

// Utils
// Dev helpers
utils.e10kDevHelpers();
utils.e10kScrollAnimation();

// Format phone numbers automatically
utils.e10kFormatPhoneNumbers();

// Remove empty paragraph tags
utils.e10kRemoveEmptyPTags(
	document.querySelectorAll(
		".site-above-footer-wrap .ast-builder-html-element, .site-footer-primary-section-2 .ast-builder-html-element, .site-footer-primary-section-3 .ast-builder-html-element, .site-footer-primary-section-4 .ast-builder-html-element"
	)
);

// Video fade
utils.e10kVideoFadeIn(document.querySelector(".background-video"));

// utils.e10kInViewport();

// ADA - Accessibility scripts, mainly fixes
// ada.init();

// Astra - Theme scripts
// astra.init();

// Dialogs
dialogContact.init();
dialogFreeEstimate.init();
dialogGlobal.init();
dialogVideo.init();

// Eligibility
eligibility.init();

// EmbedSocial
embedSocial.init();

// FAQ
faq.init();

// Pagination
pagination.init();

// Sliders
sliderBanner.init();
sliderDownload.init();
sliderGrid.init();
sliderLogo.init();
sliderTestimonial.init();

// Tests
// test.log();
coreWebVitals.init();
