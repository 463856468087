function init() {
	// Append "#job-list" anchor to pagination for jobs
	// const jobListEl = document.querySelector("#jobs-list");

	// if (jobListEl) {
	// 	const jobListPaginationAppendAnchor =
	// 		jobListPaginationUpdate(jobListEl);
	// }

	// Append "#testimonials-list" anchor to pagination for jobs
	const testimonialsListEl = document.querySelector("#testimonials-list");

	if (testimonialsListEl) {
		const testimonialsListPaginationAppendAnchor =
			testimonialsListPaginationUpdate(testimonialsListEl);
	}
}

function jobListPaginationUpdate(jobListEl) {
	const pageLinks = jobListEl.querySelectorAll("a");

	pageLinks.forEach((el) => {
		const href = el.href;

		el.setAttribute("href", href + "#jobs-list");
	});
}

function testimonialsListPaginationUpdate(testimonialsListEl) {
	const pageLinks = testimonialsListEl.querySelectorAll("a");

	pageLinks.forEach((el) => {
		const href = el.href;

		el.setAttribute("href", href + "#testimonials-list");
	});
}

export default { init };
