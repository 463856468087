function init() {
	// console.log("Eligibility");

	const eligibilityBlock = document.querySelector(".block-eligibility");
	if (eligibilityBlock) {
		const eligibilityBlockQuestions = checkQuestions(eligibilityBlock);
	}
}

// Check questions
function checkQuestions(block) {
	const allQuestions = block.querySelectorAll(".block-eligibility .question");
	let numQuestions = allQuestions.length;
	// let yesCount = 0;
	// let noCount = 0;
	let questionsAnsweredCount = 0;

	// Disable basement question to start, only enable when attic question is yes
	const insulationBasementWrapper = block.querySelector(
		".question-wrapper.insulation-basement"
	);
	const insulationBasementInputs = block.querySelectorAll(
		".question-wrapper.insulation-basement input"
	);
	insulationBasementWrapper.classList.add("disabled");
	insulationBasementInputs.forEach((input) => {
		input.disabled = true;
		input.checked = false;
	});
	numQuestions--;

	// Individual questions
	// Heat source
	let heatSource = false;
	const heatSourceEl = block.querySelector(
		".question-wrapper.heat-source .question"
	);
	const heatSourceYes = heatSourceEl.querySelector("input.yes");
	const heatSourceNo = heatSourceEl.querySelector("input.no");
	let heatSourceChange = 0;

	heatSourceYes.addEventListener("change", function (e) {
		heatSource = true;
		heatSourceChange++;

		if (heatSourceChange <= 1) {
			questionsAnsweredCount++;
		}

		// console.log("Heat source: " + heatSource);
		// console.log("Questions answered: " + questionsAnsweredCount);
		checkAnswers();
	});

	heatSourceNo.addEventListener("change", function (e) {
		heatSource = false;
		heatSourceChange++;

		if (heatSourceChange <= 1) {
			questionsAnsweredCount++;
		}

		// console.log("Heat source: " + heatSource);
		// console.log("Questions answered: " + questionsAnsweredCount);
		checkAnswers();
	});

	// Attic insulation
	let insulationAttic = false;
	const insulationAtticEl = block.querySelector(
		".question-wrapper.insulation-attic .question"
	);
	const insulationAtticYes = insulationAtticEl.querySelector("input.yes");
	const insulationAtticNo = insulationAtticEl.querySelector("input.no");
	let insulationAtticChange = 0;

	insulationAtticYes.addEventListener("change", function (e) {
		insulationAttic = true;
		insulationAtticChange++;

		if (insulationAtticChange <= 1) {
			questionsAnsweredCount++;
		}

		// Enable basement question
		insulationBasementWrapper.classList.remove("disabled");
		insulationBasementInputs.forEach((input) => {
			input.disabled = false;
		});
		numQuestions++;

		// console.log("Insulation attic: " + insulationAttic);
		// console.log("Questions answered: " + questionsAnsweredCount);
		checkAnswers();
	});

	insulationAtticNo.addEventListener("change", function (e) {
		insulationAttic = false;
		insulationAtticChange++;

		if (insulationAtticChange <= 1) {
			questionsAnsweredCount++;
		}

		// Hide basement question
		if (!insulationBasementWrapper.classList.contains("disabled")) {
			insulationBasementWrapper.classList.add("disabled");
			insulationBasementInputs.forEach((input) => {
				input.disabled = true;
				input.checked = false;
			});
			numQuestions--;

			if (questionsAnsweredCount !== numQuestions) {
				questionsAnsweredCount--;
				insulationBasementChange = 0;
			}
		}

		// console.log("Insulation attic: " + insulationAttic);
		// console.log("Questions answered: " + questionsAnsweredCount);
		checkAnswers();
	});

	// Basement insulation
	let insulationBasement = false;
	const insulationBasementEl = block.querySelector(
		".question-wrapper.insulation-basement .question"
	);
	const insulationBasementYes =
		insulationBasementEl.querySelector("input.yes");
	const insulationBasementNo = insulationBasementEl.querySelector("input.no");
	let insulationBasementChange = 0;

	insulationBasementYes.addEventListener("change", function (e) {
		insulationBasement = true;
		insulationBasementChange++;

		if (insulationBasementChange <= 1) {
			questionsAnsweredCount++;
		}

		// console.log("Insulation basement: " + insulationBasement);
		// console.log("Questions answered: " + questionsAnsweredCount);
		checkAnswers();
	});

	insulationBasementNo.addEventListener("change", function (e) {
		insulationBasement = false;
		insulationBasementChange++;

		if (insulationBasementChange <= 1) {
			questionsAnsweredCount++;
		}

		// console.log("Insulation basement: " + insulationBasement);
		// console.log("Questions answered: " + questionsAnsweredCount);
		checkAnswers();
	});

	// Household income
	let householdIncome = false;
	const householdIncomeEl = block.querySelector(
		".question-wrapper.household-income .question"
	);
	const householdIncomeYes = householdIncomeEl.querySelector("input.yes");
	const householdIncomeNo = householdIncomeEl.querySelector("input.no");
	let householdIncomeChange = 0;

	householdIncomeYes.addEventListener("change", function (e) {
		householdIncome = true;
		householdIncomeChange++;

		if (householdIncomeChange <= 1) {
			questionsAnsweredCount++;
		}

		// console.log("Household income: " + householdIncome);
		// console.log("Questions answered: " + questionsAnsweredCount);
		checkAnswers();
	});

	householdIncomeNo.addEventListener("change", function (e) {
		householdIncome = false;
		householdIncomeChange++;

		if (householdIncomeChange <= 1) {
			questionsAnsweredCount++;
		}

		// console.log("Household income: " + householdIncome);
		// console.log("Questions answered: " + questionsAnsweredCount);
		checkAnswers();
	});

	// Check answers and show dialog if all questions answered
	function checkAnswers() {
		// console.log("Num questions: " + numQuestions);
		// console.log("Questions answered: " + questionsAnsweredCount);

		if (numQuestions === questionsAnsweredCount) {
			// console.log("All questions answered");

			// All yes - income qualified
			if (
				heatSource === true &&
				insulationAttic === true &&
				insulationBasement === true &&
				householdIncome === true
			) {
				const rebateAmount = getRebateAmount(
					"income-qualified",
					insulationAttic,
					insulationBasement
				);
				getDialog("income-qualified", rebateAmount);

				// All yes with basement no - income qualified
			} else if (
				heatSource === true &&
				insulationAttic === true &&
				insulationBasement === false &&
				householdIncome === true
			) {
				const rebateAmount = getRebateAmount(
					"income-qualified",
					insulationAttic
				);
				getDialog("standard-qualified", rebateAmount);

				// All yes with household income no - standard qualified
			} else if (
				heatSource === true &&
				insulationAttic === true &&
				insulationBasement === true &&
				householdIncome === false
			) {
				const rebateAmount = getRebateAmount(
					"standard-qualified",
					insulationAttic,
					insulationBasement
				);
				getDialog("standard-qualified", rebateAmount);

				// All yes with basement no and household income no - standard qualified
			} else if (
				heatSource === true &&
				insulationAttic === true &&
				insulationBasement === false &&
				householdIncome === false
			) {
				const rebateAmount = getRebateAmount(
					"standard-qualified",
					insulationAttic
				);
				getDialog("standard-qualified", rebateAmount);

				// All no - not qualified
			} else {
				const rebateAmount = "";
				getDialog("not-qualified", rebateAmount);
			}
		} else {
			// console.log("Answers missing");
		}
	}
}

// Get rebate amount
function getRebateAmount(qualifiedStatus, insulationAttic, insulationBasement) {
	// Element that contains rebate amount data attributes
	const blockQuestionsEl = document.querySelector(
		".block-eligibility .block-questions"
	);
	const standardEnergyStar = blockQuestionsEl.getAttribute(
		"data-rebate-standard-energy-star"
	);
	const standardAtticInsulation = blockQuestionsEl.getAttribute(
		"data-rebate-standard-attic-insulation"
	);
	const standardBasementInsulation = blockQuestionsEl.getAttribute(
		"data-rebate-standard-basement-insulation"
	);
	const incomeEnergyStar = blockQuestionsEl.getAttribute(
		"data-rebate-income-energy-star"
	);
	const incomeAtticInsulation = blockQuestionsEl.getAttribute(
		"data-rebate-income-attic-insulation"
	);
	const incomeBasementInsulation = blockQuestionsEl.getAttribute(
		"data-rebate-income-basement-insulation"
	);
	let rebateAmount = "";

	// Income qualified and attic insulation false
	if (qualifiedStatus === "income-qualified" && true !== insulationAttic) {
		rebateAmount =
			"$" +
			String(Number(incomeEnergyStar) + Number(incomeBasementInsulation));
		// Income qualified and basement insulation false
	} else if (
		qualifiedStatus === "income-qualified" &&
		true !== insulationBasement
	) {
		rebateAmount =
			"$" +
			String(Number(incomeEnergyStar) + Number(incomeAtticInsulation));
		// Income qualified and both attic and basement insulation true
	} else if (
		qualifiedStatus === "income-qualified" &&
		true === insulationAttic &&
		true === insulationBasement
	) {
		rebateAmount =
			"$" +
			String(
				Number(incomeEnergyStar) +
					Number(incomeAtticInsulation) +
					Number(incomeBasementInsulation)
			);
		// Standard qualified and attic insulation false
	} else if (
		qualifiedStatus === "standard-qualified" &&
		true !== insulationAttic
	) {
		rebateAmount =
			"$" +
			String(
				Number(standardEnergyStar) + Number(standardBasementInsulation)
			);
		// Standard qualified and basement insulation false
	} else if (
		qualifiedStatus === "standard-qualified" &&
		true !== insulationBasement
	) {
		rebateAmount =
			"$" +
			String(
				Number(standardEnergyStar) + Number(standardAtticInsulation)
			);
		// Standard qualified and both attic and basement insulation true
	} else if (qualifiedStatus === "standard-qualified") {
		rebateAmount =
			"$" +
			String(
				Number(standardEnergyStar) +
					Number(standardAtticInsulation) +
					Number(standardBasementInsulation)
			);
	}

	return rebateAmount;
}

function getDialog(qualifiedStatus, rebateAmount) {
	const dialog = document.querySelector(".eligibility-dialog");
	const dialogClose = dialog.querySelector(".eligibility-dialog .close");
	const dialogContent = dialog.querySelectorAll(".content");
	const dialogFormFieldEligibilityStatus = dialog.querySelector(
		".eligibility-dialog .form .eligibility-status input"
	);

	// Show correct content based on qualified status
	dialogContent.forEach((el) => {
		if (!el.classList.contains(qualifiedStatus)) {
			el.classList.add("hidden");
		} else {
			el.classList.remove("hidden");
		}
	});

	// Set rebate amount by replacing "[rebate-amount]" string within content
	if (
		qualifiedStatus == "standard-qualified" ||
		qualifiedStatus == "income-qualified"
	) {
		let displayedContent = dialog.querySelectorAll(
			".content:not(.hidden)"
		)[0];
		const rebateAmountSpan = dialog.querySelector(
			".content:not(.hidden) .rebate-amount"
		);

		if (rebateAmountSpan) {
			rebateAmountSpan.innerHTML = rebateAmount;
		} else {
			displayedContent.innerHTML = displayedContent.innerHTML.replace(
				"[rebate-amount]",
				"<span class='rebate-amount'>" + rebateAmount + "</span>"
			);
		}
	}

	// Update eligibility status form field
	dialogFormFieldEligibilityStatus.value = qualifiedStatus;

	// Dialog
	// Open
	dialog.showModal();

	// Close
	// On ::backdrop click
	dialog.addEventListener("click", (e) => {
		if (e.target.nodeName === "DIALOG") {
			dialog.close();
		}
	});

	// On close button click
	dialogClose.addEventListener("click", (e) => {
		dialog.close();
	});
}

export default { init };
