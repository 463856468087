function init() {
	// console.log("FAQ");

	const faq = document.querySelectorAll(".block-faq");
	if (faq.length) {
		// Close all the questions that are not the targeted question
		const faqToggle = toggle(faq);
	}
}

function toggle(faq) {
	const details = faq[0].querySelectorAll("details");

	details.forEach((targetDetail) => {
		targetDetail.addEventListener("click", () => {
			details.forEach((detail) => {
				if (detail !== targetDetail) {
					detail.removeAttribute("open");
				}
			});
		});
	});
}

export default { init };
