import EmblaCarousel from "embla-carousel";
import ClassNames from "embla-carousel-class-names";
import { autoPlay } from "./autoplay";
import {
	addDotBtnsAndClickHandlers,
	addPrevNextBtnsClickHandlers,
	addTogglePrevNextBtnsActive
} from "./navigation";

function init() {
	// console.log("Banner slider");

	if (document.querySelector(".banner-slider .embla")) {
		bannerSlider();
	}
}

function bannerSlider() {
	const wrap = document.querySelector(".banner-slider .embla");
	const viewPort = wrap.querySelector(".banner-slider .embla__viewport");
	const numSlides = wrap.getAttribute("data-num-slides");
	const prevBtn = wrap.querySelector(".banner-slider .embla__button--prev");
	const nextBtn = wrap.querySelector(".banner-slider .embla__button--next");
	const dots = wrap.querySelector(".banner-slider .embla__dot_navigation");
	const embla = EmblaCarousel(
		viewPort,
		{
			active: numSlides > 1,
			align: "start",
			loop: true
		},
		[ClassNames()]
	);

	// Add disabled class if slider has 1 slide
	if (numSlides === "1") {
		wrap.classList.add("disabled");
	}

	// Previous/next navigation
	const removePrevNextBtnsClickHandlers = addPrevNextBtnsClickHandlers(
		embla,
		prevBtn,
		nextBtn
	);

	const removeTogglePrevNextBtnsActive = addTogglePrevNextBtnsActive(
		embla,
		prevBtn,
		nextBtn
	);

	// Dot navigation
	const removeDotBtnsAndClickHandlers = addDotBtnsAndClickHandlers(
		embla,
		dots
	);

	// Autoplay
	const auto = autoPlay(embla, viewPort, 5000);

	// Ready
	const toggleEmblaReady = (event) => {
		const isResizeEvent = event === "resize";
		const toggleClass = isResizeEvent ? "remove" : "add";
		viewPort.classList[toggleClass]("embla--is-ready");
		if (isResizeEvent) embla.reInit();
	};

	embla.on("init", toggleEmblaReady);
	embla.on("resize", toggleEmblaReady);
	embla.on("reInit", toggleEmblaReady);
}

export default { init };
