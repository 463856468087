import utils from "../utils";

function init() {
	const videoDialogEl = document.querySelectorAll(".video-dialog");

	if (videoDialogEl) {
		const videoDialog = dialogEventListeners({
			open: document.querySelectorAll(".video-dialog-link"),
			dialog: videoDialogEl,
			close: document.querySelectorAll(".video-dialog .close")
		});
	}
}

function autoplayVideo(dialog) {
	// console.log("Autoplay!");

	const iframe = dialog.querySelector("iframe");
	const iframeSrc = iframe.src;
	const iframeDataSrc = iframe.getAttribute("data-src");

	// Add allow="autoplay"
	iframe.setAttribute("allow", "autoplay");

	// Vimeo: Append &autoplay=1 to src
	if (!iframeSrc.includes("&autoplay=1") && !iframeDataSrc) {
		iframe.setAttribute("src", iframeSrc + "&autoplay=1");

		// YouTube: Remove data-src attribute and append &autoplay=1 to src
	} else if (!iframeSrc.includes("&autoplay=1")) {
		iframe.removeAttribute("data-src");
		iframe.setAttribute("src", iframeDataSrc + "&autoplay=1");
	}
}

function stopVideo(dialog) {
	// console.log("Stop video!");

	const iframe = dialog.querySelector("iframe");
	const iframeSrc = iframe.src;

	// Remove allow="autoplay"
	iframe.setAttribute("allow", "");

	// Remove &autoplay=1 from src
	iframe.setAttribute("src", iframeSrc.replace("&autoplay=1", ""));
}

function openDialog(e) {
	// console.log("Open dialog!");

	const item = e.target.closest(".item, .column");
	const dialog = item.querySelector("dialog");

	e.preventDefault();
	dialog.showModal();
	autoplayVideo(dialog);
}

// Close dialog
function closeDialog(e) {
	// console.log("Close dialog!");

	const item = e.target.closest(".item, .column");
	const dialog = item.querySelector("dialog");

	dialog.close();
	stopVideo(dialog);
}

// Dialog add event listeners
function dialogEventListeners(args) {
	// Open
	args.open.forEach((el) => {
		el.addEventListener("click", (e) => {
			openDialog(e, args.dialog);
		});
	});

	// Close
	// On ::backdrop click
	args.dialog.forEach((el) => {
		el.addEventListener("click", (e) => {
			if (e.target.nodeName === "DIALOG") {
				closeDialog(e);
			}
		});
	});

	// On close button click
	args.close.forEach((el) => {
		el.addEventListener("click", (e) => {
			closeDialog(e);
		});
	});
}

export default { init };
