// Autoplay
export const autoPlay = (embla, viewPort, interval) => {
	const lastIndex = embla.scrollSnapList().length - 1;
	const state = { timer: 0 };

	const play = () => {
		stop();
		requestAnimationFrame(
			() => (state.timer = window.setTimeout(next, interval))
		);
	};

	const stop = () => {
		window.clearTimeout(state.timer);
		state.timer = 0;
	};

	const next = () => {
		if (embla.selectedScrollSnap() === lastIndex) {
			embla.scrollTo(0);
		} else {
			embla.scrollNext();
		}
		play();
	};

	viewPort.addEventListener("mouseenter", stop, false);
	viewPort.addEventListener("mouseleave", play, false);
	viewPort.addEventListener("touchstart", stop, false);
	play();

	return { play, stop };
};
