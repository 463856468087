function init() {
	// Remove archive description from specific pages
	const embedSocialSection = document.querySelectorAll(
		".embedsocial-shortcode"
	);

	if (embedSocialSection) {
		const embedSocialScripts = addEmbedSocialScripts();
	}
}

function addEmbedSocialScripts() {
	// First check if there is already an embed script on the page
	if (document.body.classList.contains("embedsocial-script-active")) {
		return false;

		// Add Embed script to page and set class on body
	} else {
		(function (d, s, id) {
			var js;
			if (d.getElementById(id)) {
				return;
			}
			js = d.createElement(s);
			js.id = id;
			js.src = "https://embedsocial.com/cdn/ht.js";
			d.getElementsByTagName("head")[0].appendChild(js);
		})(document, "script", "EmbedSocialHashtagScript");

		document.body.classList.add("embedsocial-script-active");
	}
}

export default { init };
